import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'
import Section from '@components/Section'
import { Link } from 'gatsby'
import { Box, Heading, Button } from 'theme-ui'

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Chi Siamo' lang="it" description="Siamo una claim company con l'obiettivo di fornire assistenza specialistica ai passeggeri aerei che subiscono un disservizio" />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Il tuo volo ha subito un ritardo o è stato cancellato?"
          subheader='Ottieni fino a 
          600 € di risarcimento
        per passeggero, a prescindere dal prezzo del biglietto. Se non vinciamo, non ci devi nulla!'
          
        />
   
   
        <Divider />
        </Main>
        </Stack>
        <Stack>   
        <Divider />
        <Main>
        <Commitment />
        </Main>
    </Stack>
    <Stack>
        <Main>
          <ContactForm />
        </Main>
      </Stack>
  </Layout>
)

export default PageContact
